import moment from "moment-timezone";
import { CONSTANTS } from "consts";
import { Action, AdminAppContext } from "contexts";
import * as React from "react";
import { useHistory } from "react-router-dom";
import GLOBAL_FUNCTIONS from "scripts/apps/global-functions";
import { useAdminStructuredProgramsQuery, useGetAppsQuery } from "services";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { errorHandle, setTimeWithTimeZone } from "utils";
import { APP_ID_ROWER_GROUP, formatAppData } from "consts/app";

// const sample = {
//   "appId": 1, //required
//   "spId": "sp-beta-001", //required
//   "spInfo": {
//       "title": "sp-beta", //required
//       "description": "sp-betaaaa", //required
//       "longDesc": "By following this program, you will conquer this month's challenge by engaging in a range of workouts. Each day, a new workout will be introduced, encompassing power, strength, and cardio programs. Make sure to complete one workout each day.", //required
//       "difficulty": "EASY", //required
//       "length": "10 Days", //required
//       "intensity": "7 Days/Week", //required
//       "startTime": 1726653559, //required
//       "endTime": 1726653559, //required
//       "hasStreaming": true, //required
//       "isSuggest": true, //required
//       "tag": "hi anh trai",
//       "note": "abc"
//   }
// }

const useMainHook = () => {
  const { global, globalDispatch } = React.useContext(AdminAppContext);

  React.useEffect(() => {
    if (global.current_page !== CONSTANTS.SIDEBAR_PAGEID.STRUCTURED_PROGRAM) {
      globalDispatch(
        Action.global.setState({
          current_page: CONSTANTS.SIDEBAR_PAGEID.STRUCTURED_PROGRAM,
        })
      );
    }
  }, [global.current_page, globalDispatch]);

  const history = useHistory();

  const machineTypeOptions: {
    value: string;
    label: string;
    name: string;
    active: number;
  }[] = [
    { label: "All", value: "", name: "status", active: 0 },
    { label: "APK", value: "apk", name: "status", active: 1 },
    { label: "C2", value: "c2", name: "status", active: 2 },
  ];

  const [state, setState] = React.useState({
    columns: [
      {
        numeric: false,
        disablePadding: false,
        label: "ID",
        id: "spId",
        hasSub: false,
      }, 
      {
        numeric: false,
        disablePadding: false,
        label: "Thumb",
        id: "thumbnailUrl",
        hasSub: false,
        className: "iconCell",
      },
      {
        numeric: false,
        disablePadding: false,
        label: 'Title',
        id: "title",
        hasSub: false,
      },
      {
        numeric: false,
        disablePadding: false,
        label: "Description",
        id: "description",
        hasSub: false,
      },
      {
        numeric: false,
        disablePadding: false,
        label: "Difficulty",
        id: "difficulty",
        hasSub: false,
      },
      {
        numeric: false,
        disablePadding: false,
        label: "Length",
        id: "length",
        hasSub: false,
      },
      {
        numeric: false,
        disablePadding: false,
        label: "Start Time",
        id: "startTime",
        type: "date",
      },
      {
        numeric: false,
        disablePadding: false,
        label: "End Time",
        id: "endTime",
        type: "date",
      }
    ],
    rows: [],

    totalCount: 0,
  });
  const [loading, setLoading] = React.useState(false);

  const location = useLocation();
  const search = location.search;
  const searchParams = new URLSearchParams(search);
  const searchText = searchParams.get("seach");
  const pageSizeParam = searchParams.get("pageSize") || 10;
  const pageParam = searchParams.get("page") || 0;
  const appIdParam = searchParams.get("appId") || "";
  const [queryParameter, setQueryParameter] = React.useState<any>({
    pageSize: Number(pageSizeParam) || 20,
    page: Number(pageParam) || 0,
    search: searchText || "",
    appId: appIdParam || APP_ID_ROWER_GROUP,
  });
  let {
    data: structuredPrograms,
    loading: loadingSP,
    refetch: refetchSP,
  } = useAdminStructuredProgramsQuery({
    variables: {
      input: {
        page: Number(queryParameter?.page) + 1,
        pageSize: Number(queryParameter?.pageSize),
        appId: Number(queryParameter?.appId) || "",
        // sortValue: getSortParams(queryParameter.sort)?.sortValue,
        // sortBy: getSortParams(queryParameter.sort)?.sortBy,
        searchText: queryParameter?.searchText,
      },
    } as any,
    fetchPolicy: "no-cache",
  });

  const {
    data: apps,
    loading: loadingApps,
    refetch: refetchApps,
  } = useGetAppsQuery({
    variables: {},
    fetchPolicy: "no-cache",
  });

  React.useEffect(() => {
    let urlParams = `?page=${queryParameter.page}&pageSize=${queryParameter.pageSize}`;
    if (queryParameter.appId) {
      urlParams = urlParams + `&appId=${queryParameter.appId}`;
    }
    // if (queryParameter.type) {
    //   urlParams = urlParams + `&type=${queryParameter.type}`;
    // }
    if (queryParameter.searchText) {
      urlParams = urlParams + `&searchText=${queryParameter.searchText}`;
    }
    // if (queryParameter.sort) {
    //   urlParams = urlParams + `&sort=${queryParameter.sort}`;
    // }
    // if (queryParameter.status) {
    //   urlParams = urlParams + `&status=${queryParameter.status}`;
    // }
    history.replace(urlParams);
  }, [queryParameter]);

  React.useEffect(() => {
    if (structuredPrograms && structuredPrograms?.adminStructuredPrograms?.data) {
      const rows = structuredPrograms?.adminStructuredPrograms?.data.map((el: any) => {
        return {
          spId: el.spId,
          title: el.title,
          description: el.description,
          longDesc: el.longDesc,
          difficulty: el.difficulty,
          length: el.length,
          intensity: el.intensity,
          startTime: moment.unix(el.startTime).format('YYYY/MM/DD'),
          endTime: moment.unix(el.endTime).format('YYYY/MM/DD'),
          hasStreaming: el.hasStreaming,
          isSuggest: el.isSuggest,
          tag: el.tag,
          note: el.note,
          thumbnailUrl: <img src={el.thumbnailUrl} alt="Icon" />,
      }
      })
      setState({
        ...state,
        //@ts-ignore
        rows,
        totalCount: structuredPrograms?.adminStructuredPrograms?.total,
      });
    } else {
        setState({
            ...state,
            rows: [],
            totalCount: 0,
        })
    }
  }, [structuredPrograms, apps]);

  const handleChangePage = (newPage: number) => {
    setQueryParameter({
      ...queryParameter,
      page: Number(newPage),
    });
  };

  const handleChangeApp = async (event: any) => {
    //@ts-ignore
    setQueryParameter({
      ...queryParameter,
      appId: event?.target?.value,
      page: 0,
    });
  };

  const handleSearch = (newValue: any) => {
    setQueryParameter({
      ...queryParameter,
      searchText: newValue,
      page: 0,
    });
  };

  const onSearchKeyDown = (event: any) => {
    if (event.keyCode === 13) {
      handleSearch(event.target.value);
    }
  };

  const onChangeAdvanceFilter = (data: any) => {
    setQueryParameter({
      ...queryParameter,
      type: data.type.value,
      supportCategoryId: data.supportCategoryId.value,
      offset: 0,
      page: 0,
    });
  };

  const onChangeInput = (event: any) => {
    const { value, name } = event.target;
    setState(
      GLOBAL_FUNCTIONS.SetObject(state, {
        [name]: value,
      })
    );
  };

  const handleCloseAction = (event: any, id: any) => {
    const products = state.rows.map((x: any) => {
      const item: any = { ...x };
      if (item.id === id) {
        item.open = item.open !== undefined ? !item.open : true;
      } else {
        item.open = false;
      }
      return item;
    });
    setState(
      GLOBAL_FUNCTIONS.SetObject(state, {
        rows: products,
      })
    );
  };

  const handleViewDetail = (event: any, item: any) => {
    history.push(`${CONSTANTS.NAVIGATION_URL.STRUCTURED_PROGRAM}/${queryParameter?.appId}/${item.spId}`);
  };

  const goToSub = (even: any, item: any) => {
    const path = `${CONSTANTS.NAVIGATION_URL.STRUCTURED_PROGRAM}/${queryParameter?.appId}/${item.spId}`;
    history.push({ pathname: path });
  };

  const handleAddPost = () => {
    const path = `${CONSTANTS.NAVIGATION_URL.STRUCTURED_PROGRAM}/${queryParameter?.appId}/add`;
    history.push(path);
  };

  const handleChangeType = (e: any) => {
    const value = e.target.value;
    setQueryParameter({ ...queryParameter, type: value });
  };

  const handleChangeStatus = (event: any) => {
    const value = event.target.value;
    setQueryParameter({ ...queryParameter, status: value });
  }

  const propsTable = {
    data: {
      ...state,
      currentPage: queryParameter.page,
    },
    goToSub,
    action: {
      detail: true,
    },
    handleClose: handleCloseAction,
    handleChangePage,
    handleViewDetail,
    // statusTab: {
    //   options: machineTypeOptions,
    //   itemActive: machineTypeOptions[0],
    // },
    loading: loadingSP || loadingApps,
    isHideNumberCol: true,
  };
  return {
    propsTable,
    state,
    loading: loadingSP || loading,
    handleAddPost,
    handleSearch,
    onSearchKeyDown,
    onChangeInput,
    // onChangeSelect,
    onChangeAdvanceFilter,
    queryParameter,
    // handleChangeSort,
    handleChangeType,
    handleChangeApp,
    handleChangeStatus,
  };
};

export default useMainHook;
