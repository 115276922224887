import moment from "moment-timezone";
import { CONSTANTS } from "consts";
import { Action, AdminAppContext } from "contexts";
import { useFormik } from "formik";
import { get } from "lodash";
import React, { useRef, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  api,
  useAdminCreateStructuredProgramMutation,
  useCreateUploadPhotoUrlMutation,
  useGetGameInfoDataByAppQuery,
  useGetSpRequirementsQuery,
  useAdminGetSpListWorkoutsQuery,
  useGetStructuredProgramQuery,
} from "services";
import Swal from "sweetalert2";
import { formSchema } from "./formSchemal";
import { errorHandle, setTimeWithTimeZone } from "utils";
import exportCsv from "scripts/apps/exportCsv";

type programType = {
  key: number;
  name: string;
  gameId: string;
  gameType: number;
  description: string;
  requirements: object;
  dueDate: number;
  thumbnail: string;
  thumbnailUrl: string;
}
type ItemProps = {
  key: number;
  name: string;
  gameId: string;
  gameType: number;
  description: string;
  requirements: any;
  dueDate: number;
  thumbnail: string;
  thumbnailUrl: string;
}

const itemDefault: programType = {
  key: 1,
  name: "METRICS MONITOR",
  gameId: "Game_0",
  gameType: 8,
  description: "",
  requirements: {
    activetime: 10,
  },
  dueDate: 7,
  thumbnail: "homeItemIcon0.png",
  thumbnailUrl: "",
}

const mapApps  = {
  17: 1,
  384: 128,
  1536: 512,
}

const useDetailHook = () => {
  const { globalDispatch, global } = React.useContext(AdminAppContext);

  const params = useParams();

  useEffect(() => {
    if (global.current_page !== CONSTANTS.SIDEBAR_PAGEID.STRUCTURED_PROGRAM) {
      globalDispatch(
        Action.global.setState({
          current_page: CONSTANTS.SIDEBAR_PAGEID.STRUCTURED_PROGRAM,
        })
      );
    }
  }, [global.current_page, globalDispatch]);

  let {
    data: sp,
    loading: loadingSP
  } = useGetStructuredProgramQuery({
    variables: {
      input: {
        //@ts-ignore
        appId: Number(params?.appId),
        //@ts-ignore
        spId: params?.id,
      },
    },
    fetchPolicy: "no-cache",
  }); 

  let {
    data: gi,
  } = useGetGameInfoDataByAppQuery({
    variables: {
      input: {
        //@ts-ignore
        appId: mapApps[Number(params?.appId)] || Number(params?.appId),
      },
    },
    fetchPolicy: "no-cache",
  }); 

  let {
    data: programs,
    loading: loadingPrograms,
  } = useAdminGetSpListWorkoutsQuery({
    variables: {
      input: {
        //@ts-ignore
        appId: Number(params?.appId),
        //@ts-ignore
        spId: params?.id,
        page: 1,
        pageSize: 100
      },
    },
    fetchPolicy: "no-cache",
  }); 

  let {
    data: requirements,
  } = useGetSpRequirementsQuery({
    variables: {
      input: {},
    },
    fetchPolicy: "no-cache",
  }); 

  const history = useHistory();
  const [createStructuredProgram] = useAdminCreateStructuredProgramMutation();
  const [createUploadUrl] = useCreateUploadPhotoUrlMutation();

  const [state, setState] = React.useState<any>({
    item: null,
    open: false,
  });

  const [thumbFile, setThumbFile] = React.useState({} as File);

  const conditionFormik = useRef<any>();
  const dn = Date.now()
  const formik = useFormik({
    initialValues: {
      spId: "",
      title: "",
      description: "",
      longDesc: "",
      difficulty: "EASY",
      length: "",
      intensity: "",
      startTime: moment(dn).subtract(1,'months').startOf('month'),
      endTime: moment(dn).subtract(1,'months').endOf('month'),
      hasStreaming: false,
      isSuggest: true,
      tag: "",
      note: "",
      thumbnailUrl: "",
      items: [] as programType[],
    },
    validationSchema: formSchema,
    onSubmit: (values) => {
      conditionFormik?.current?.submitForm();
    },
  });

  useEffect(() => {
    if (programs && programs?.adminGetSPListWorkouts?.data) {
      const items = programs?.adminGetSPListWorkouts?.data
      formik.setFieldValue('items', items)
    }
  }, [programs]);

  useEffect(() => {
    if (sp && sp?.getStructuredProgram) {
      const {
        spId,
        title,
        description,
        longDesc,
        difficulty,
        length,
        intensity,
        startTime,
        endTime,
        hasStreaming,
        isSuggest,
        tag,
        note,
        thumbnailUrl,
      } = sp?.getStructuredProgram
      formik.setValues({
        spId,
        title,
        description,
        longDesc,
        difficulty,
        length,
        intensity,
        startTime: moment.unix(startTime),
        endTime: moment.unix(endTime),
        hasStreaming,
        isSuggest,
        tag,
        note,
        thumbnailUrl,
        items: formik.values.items,
      })
    }
  }, [sp]);

  const [loading, setLoading] = React.useState(false);

  const setBack = () => {
    history.goBack();
  };

  const handleSubmitForms = async () => {
    setLoading(true);
    await formik.submitForm();
    if (formik.values.spId.trim() !== "") {
      if (
        formik.isValid 
      ) {
        setLoading(false);
        const result = await Swal.fire({
          title: "Are you sure?",
          icon: "warning",
          text: `You won't be able to revert this!`,
          showCancelButton: true,
          confirmButtonText: "Yes, save it!",
        });
        if (!result.isConfirmed) {
          return;
        }
        setLoading(true);
        if (thumbFile && Object.keys(thumbFile).length) {
          const uploadData = await api.spIconUploadAPI.uploadSpIcon(
            thumbFile,
          )
        }
        const items = [...formik.values.items]
        createStructuredProgram({
          variables: {
            input: {
              //@ts-ignore
              appId: Number(params?.appId),
              spId: formik.values.spId.trim(),
              spInfo: {
                title: formik.values.title,
                description: formik.values.description,
                longDesc: formik.values.longDesc,
                difficulty: formik.values.difficulty,
                length: formik.values.length,
                intensity: formik.values.intensity,
                startTime: formik.values.startTime.unix(),
                endTime: formik.values.endTime.unix(),
                hasStreaming: formik.values.hasStreaming,
                isSuggest: formik.values.isSuggest,
                tag: formik.values.tag,
                note: formik.values.note,
              },
              items,
            },
          },
        }).then((res)=>{
          setLoading(false);
          toast.success("Create StructuredProgram Success", { autoClose: 3000 });
          history.push(CONSTANTS.NAVIGATION_URL.STRUCTURED_PROGRAM)
        }).catch((err)=>{
          errorHandle(err, formik)
          setLoading(false)
        }).finally(()=>{
          setLoading(false);
        });
      } else if (!formik.isValid) {
        setLoading(false);
        const el = document.getElementById("profileFormId");
        el?.scrollIntoView();
        return;
      } else if (!conditionFormik?.current?.isValid) {
        const el = document.getElementById("conditionFormId");
        el?.scrollIntoView();
        setLoading(false);
        return;
      } else {
        setLoading(false);
        alert("have error");
      }
    } else {
      setLoading(false);
    }
  };
  const addMoreProgram = () => {
    const newItem = Object.assign({}, itemDefault)
    const items = [...formik.values.items]
    if (items.length) {
      const newKey = Math.max(...formik.values.items.map((e: any) => e.key)) + 1
      newItem.key = newKey
    }
    items.push(newItem)
    formik.setFieldValue('items', items)
  }

  const onAddProgram = (item: any) => {
    const items = [...formik.values.items]
    const idx = items.findIndex(el => el.key == item.key)
    if (idx != -1) {
      items[idx] = item
    } else {
      items.push(item)
    }
    formik.setFieldValue('items', items)
    handleClose()
  }

  const onDelProgram = (item: any) => {
    const items = formik.values.items.filter(el => el.key != item.key)
    formik.setFieldValue('items', items)
    handleClose()
  }

  const onEditProgram = (item: any) => {
    setState({
      item: item,
      open: true,
    })
  }

  const handleClose = () => {
    setState({
      item: null,
      open: false,
    })
  }

  const changeImage = (acceptedFile: any, url: string) => {
    setThumbFile(acceptedFile)
    formik.setFieldValue("ThumbnailUrl", url, true);
  }

  const changeStartTime = (name: any, date: any) => {
    formik.setFieldValue("startTime", moment(date).utc(true).startOf('day'), true);
  }

  const changeEndTime = (name: any, date: any) => {
    formik.setFieldValue("endTime", moment(date).utc(true).startOf('day'), true);
  }

  const handleExportCSV = () => {
    let csvContent = "data:text/csv;charset=utf-8,"
    // top section
    //@ts-ignore
    const spId = params?.id
    csvContent += ',Title,' + formik.values.title + '\r\n'
    csvContent += ',Description,"' + formik.values.description + '"\r\n'
    csvContent += ',Long Description,"' + formik.values.longDesc + '"\r\n'
    csvContent += ',Difficulty,' + formik.values.difficulty + '\r\n'
    csvContent += ',Length,' + formik.values.length + '\r\n'
    csvContent += ',Intensity,"' + formik.values.intensity + '"\r\n'
    csvContent += ',Start Date,' + formik.values.startTime + '\r\n'
    csvContent += ',End Date,' + formik.values.endTime + '\r\n'
    csvContent += ',Note,' + formik.values.note + '\r\n'
    csvContent += ",SP ID," + spId + "\r\n"
    
    //programs
    csvContent += ",No.,Name,Game_ID,GameType,Due Date,Require 1,Target 1,Require 2,Target 2,Require 3,Target 3,INSTRUCTION (MAX 38-55 CHAR),Thumbnail,Display Time\r\n"
    formik.values.items.map((el: ItemProps) => {
      const gId = el.gameId
      csvContent += ','
      csvContent += el.key + ','
      csvContent += el.name + ','
      csvContent += gId + ','
      csvContent += el.gameType + ','
      csvContent += el.dueDate + ','
      const keys = Object.keys(el.requirements)
      for (let i = 0; i < 3; i++) {
        if (keys[i]) {
          const req = (requirements?.getSPRequirements?.data || []).find((r: any) => r && r?.type == keys[i])
          csvContent += req?.name || ''
          csvContent += ','
          csvContent += el.requirements[keys[i]] || ''
          csvContent += ','
        } else {
          csvContent += ',,'
        }
      }
      csvContent += el.description + ','
      csvContent += el.thumbnail + ','
      const g = (gi?.getGameInfoDataByApp || []).find((el: any) => el.ID == gId)
      csvContent += g?.DisplayTimeInfo || '' + ','
      csvContent += "\r\n"
    })

    var encodedUri = encodeURI(csvContent);
    var link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", `${spId}-${formik.values.title}.csv`);
    document.body.appendChild(link); 
    link.click();
  }

  return {
    loading: loading || loadingSP || loadingPrograms,
    state,
    setBack,
    formik,
    conditionFormik,
    handleSubmitForms,
    addMoreProgram,
    gameInfos: gi?.getGameInfoDataByApp || [],
    requirements: requirements?.getSPRequirements?.data || [],
    onAddProgram,
    onDelProgram,
    onEditProgram,
    handleClose,
    changeImage,
    changeStartTime,
    changeEndTime,
    handleExportCSV,
  };
};

export default useDetailHook;
