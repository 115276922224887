import moment from "moment-timezone";
import { CONSTANTS } from "consts";
import { Action, AdminAppContext } from "contexts";
import { useFormik } from "formik";
import { get } from "lodash";
import React, { useRef } from "react";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  api,
  useAdminCreateStructuredProgramMutation,
  useCreateUploadPhotoUrlMutation,
  useGetGameInfoDataByAppQuery,
  useGetSpRequirementsQuery,
} from "services";
import Swal from "sweetalert2";
import { formSchema } from "./formSchemal";
import { errorHandle, setTimeWithTimeZone } from "utils";

type programType = {
  key: number;
  name: string;
  gameId: string;
  gameType: number;
  description: string;
  requirements: object;
  dueDate: number;
  thumbnail: string;
}

const itemDefault: programType = {
  key: 1,
  name: "METRICS MONITOR",
  gameId: "Game_0",
  gameType: 8,
  description: "",
  requirements: {
    activetime: 10,
  },
  dueDate: 7,
  thumbnail: "homeItemIcon0.png",
}

const mapApps  = {
  17: 1,
  384: 128,
  1536: 512,
}

const useAddHook = () => {
  const { globalDispatch, global } = React.useContext(AdminAppContext);

  const params = useParams();

  React.useEffect(() => {
    if (global.current_page !== CONSTANTS.SIDEBAR_PAGEID.STRUCTURED_PROGRAM) {
      globalDispatch(
        Action.global.setState({
          current_page: CONSTANTS.SIDEBAR_PAGEID.STRUCTURED_PROGRAM,
        })
      );
    }
  }, [global.current_page, globalDispatch]);

  let {
    data: gi,
  } = useGetGameInfoDataByAppQuery({
    variables: {
      input: {
        //@ts-ignore
        appId: mapApps[Number(params?.appId)] || Number(params?.appId),
      },
    },
    fetchPolicy: "no-cache",
  }); 

  let {
    data: requirements,
  } = useGetSpRequirementsQuery({
    variables: {
      input: {},
    },
    fetchPolicy: "no-cache",
  }); 

  const history = useHistory();
  const [createStructuredProgram] = useAdminCreateStructuredProgramMutation();
  const [createUploadUrl] = useCreateUploadPhotoUrlMutation();

  const [state, setState] = React.useState<any>({
    item: null,
    open: false,
  });

  const [thumbFile, setThumbFile] = React.useState({} as File);

  const conditionFormik = useRef<any>();
  const dn = Date.now()
  const formik = useFormik({
    initialValues: {
      spId: "",
      title: "",
      description: "",
      longDesc: "",
      difficulty: "EASY",
      length: "",
      intensity: "",
      startTime: moment(dn).subtract(1,'months').startOf('month'),
      endTime: moment(dn).subtract(1,'months').endOf('month'),
      hasStreaming: false,
      isSuggest: true,
      tag: "",
      note: "",
      thumbnailUrl: "",
      items: [] as programType[],
    },
    validationSchema: formSchema,
    onSubmit: (values) => {
      conditionFormik?.current?.submitForm();
    },
  });

  const [loading, setLoading] = React.useState(false);

  const setBack = () => {
    history.goBack();
  };

  const handleSubmitForms = async () => {
    setLoading(true);
    await formik.submitForm();
    if (formik.values.spId.trim() !== "") {
      if (
        formik.isValid 
      ) {
        if (thumbFile && Object.keys(thumbFile).length) {
          const uploadData = await api.spIconUploadAPI.uploadSpIcon(
            thumbFile,
          )
        }
        setLoading(false);
        const result = await Swal.fire({
          title: "Are you sure?",
          icon: "warning",
          text: `You won't be able to revert this!`,
          showCancelButton: true,
          confirmButtonText: "Yes, save it!",
        });
        if (!result.isConfirmed) {
          return;
        }
        setLoading(true);
        const items = [...formik.values.items] || []
        createStructuredProgram({
          variables: {
            input: {
              //@ts-ignore
              appId: Number(params?.appId),
              spId: formik.values.spId,
              spInfo: {
                title: formik.values.title,
                description: formik.values.description,
                longDesc: formik.values.longDesc,
                difficulty: formik.values.difficulty,
                length: formik.values.length,
                intensity: formik.values.intensity,
                startTime: formik.values.startTime.unix(),
                endTime: formik.values.endTime.unix(),
                hasStreaming: formik.values.hasStreaming,
                isSuggest: formik.values.isSuggest,
                tag: formik.values.tag,
                note: formik.values.note,
              },
              items,
            },
          },
        }).then((res)=>{
          setLoading(false);
          toast.success("Create StructuredProgram Success", { autoClose: 3000 });
          history.push(CONSTANTS.NAVIGATION_URL.STRUCTURED_PROGRAM)
        }).catch((err)=>{
          errorHandle(err, formik)
          setLoading(false)
        }).finally(()=>{
          setLoading(false);
        });
      } else if (!formik.isValid) {
        setLoading(false);
        const el = document.getElementById("profileFormId");
        el?.scrollIntoView();
        return;
      } else if (!conditionFormik?.current?.isValid) {
        const el = document.getElementById("conditionFormId");
        el?.scrollIntoView();
        setLoading(false);
        return;
      } else {
        setLoading(false);
        alert("have error");
      }
    } else {
      setLoading(false);
    }
  };
  const addMoreProgram = () => {
    const newItem = Object.assign({}, itemDefault)
    const items = [...formik.values.items]
    if (items.length) {
      const newKey = Math.max(...formik.values.items.map((e: any) => e.key)) + 1
      newItem.key = newKey
    }
    items.push(newItem)
    formik.setFieldValue('items', items)
  }

  const onAddProgram = (item: any) => {
    const items = [...formik.values.items]
    const idx = items.findIndex(el => el.key == item.key)
    if (idx != -1) {
      items[idx] = item
    } else {
      items.push(item)
    }
    formik.setFieldValue('items', items)
    handleClose()
  }

  const onDelProgram = (item: any) => {
    const items = formik.values.items.filter(el => el.key != item.key)
    formik.setFieldValue('items', items)
    handleClose()
  }

  const onEditProgram = (item: any) => {
    setState({
      item: item,
      open: true,
    })
  }

  const handleClose = () => {
    setState({
      item: null,
      open: false,
    })
  }

  const changeImage = (acceptedFile: any, url: string) => {
    setThumbFile(acceptedFile)
    formik.setFieldValue("ThumbnailUrl", url, true);
  }

  const changeStartTime = (name: any, date: any) => {
    formik.setFieldValue("startTime", moment(date).utc(true).startOf('day'), true);
  }

  const changeEndTime = (name: any, date: any) => {
    formik.setFieldValue("endTime", moment(date).utc(true).startOf('day'), true);
  }

  return {
    loading,
    state,
    setBack,
    formik,
    conditionFormik,
    handleSubmitForms,
    addMoreProgram,
    gameInfos: gi?.getGameInfoDataByApp || [],
    requirements: requirements?.getSPRequirements?.data || [],
    onAddProgram,
    onDelProgram,
    onEditProgram,
    handleClose,
    changeImage,
    changeStartTime,
    changeEndTime
  };
};

export default useAddHook;
