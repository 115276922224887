export const ENV = {
    SERVER_DOMAIN: process.env.REACT_APP_SERVER_DOMAIN as string,
    GAME_SERVER_HTTP_KEY: process.env.REACT_APP_GAME_SERVER_HTTP_KEY  as string,
    CRM_SERVER_DOMAIN: process.env.REACT_APP_CRM_SERVER_DOMAIN  as string,
    CRM_SERVER_CONTEXT: process.env.REACT_APP_CRM_SERVER_CONTEXT  as string,
    GAME_SERVER_DOMAIN: "https://" + process.env.REACT_APP_GAME_SV_URL  as string,
    PAYMENT: {
        STRIPE_PUBLIC_KEY: process.env.REACT_APP_STRIPE_PUBLIC_KEY  as string
    },
    UPLOAD_SERVICE_URL: process.env.REACT_APP_UPLOAD_SERVICE_URL  as string,
    CLIENT_ID_UPLOAD: process.env.REACT_APP_CLIENT_ID_UPLOAD  as string,
    SECRET_KEY_UPLOAD: process.env.REACT_APP_SECRET_KEY_UPLOAD  as string,
    MEMBERSHIP: {
        FREE_6_MONTH_GAME_PLAN_ID: process.env.REACT_APP_MEMBERSHIP_FREE_6_MONTH_GAME_PLAN_ID as string,
        FREE_1_MONTH_GAME_PLAN_ID: process.env.REACT_APP_MEMBERSHIP_FREE_1_MONTH_GAME_PLAN_ID as string,
    }
}   
