export const difficulties = ["EASY", "MEDIUM", "HARD", "PAIN"]
export const spLength = [
  '1 Week',
  '2 Weeks',
  '3 Weeks',
  '4 Weeks',
  '5 Weeks',
  '6 Weeks',
  '7 Weeks',
  '8 Weeks',
  '9 Weeks',
  '10 Weeks',
  '11 Weeks',
  '12 Weeks',
]
export const spIntensities = [
  '1 Day/Week',
  '2 Days/Week',
  '3 Days/Week',
  '4 Days/Week',
  '5 Days/Week',
  '6 Days/Week',
  '7 Days/Week',
  '1x/Day, 1 Day/Week',
  '1x/Day, 2 Days/Week',
  '1x/Day, 3 Days/Week',
  '1x/Day, 4 Days/Week',
  '1x/Day, 5 Days/Week',
  '1x/Day, 6 Days/Week',
  '1x/Day, 7 Days/Week',
  '2x/Day, 1 Day/Week',
  '2x/Day, 2 Days/Week',
  '2x/Day, 3 Days/Week',
  '2x/Day, 4 Days/Week',
  '2x/Day, 5 Days/Week',
  '2x/Day, 6 Days/Week',
  '2x/Day, 7 Days/Week',
  '3x/Day, 1 Day/Week',
  '3x/Day, 2 Days/Week',
  '3x/Day, 3 Days/Week',
  '3x/Day, 4 Days/Week',
  '3x/Day, 5 Days/Week',
  '3x/Day, 6 Days/Week',
  '3x/Day, 7 Days/Week',
]